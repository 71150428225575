import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	.links-group {
		display: flex;
		gap: 0.5em;

		.link {
			position: relative;
			color: ${vars.colorWhite};
			font-weight: 300;
			font-size: 14px;
			display: flex;
			align-items: center;

			&:first-of-type {
				.link__anchor {
					padding-left: 0;
				}
			}

			&__anchor {
				display: flex;
				align-items: center;
				padding: 0.8em 1em;
				text-decoration: none;
				color: currentColor;
				text-align: center;
				cursor: pointer;
				transition: color 0.2s ease;

				&::before {
					content: attr(bolded-text);
					position: absolute;
					font-weight: 500;
					z-index: 2;
					color: transparent;
					transition: color 0.2s ease;
				}
			}

			&__chevron {
				cursor: pointer;
				padding: 0 1em 0 0.5em;
			}

			&--highlighted {
				a {
					padding: 0.5em 0.5em 0.5em;
					background-color: #ba9448;
					border-radius: 0.2em;

					&:before {
						content: '';
						background: url('/images/home/gift-solid.svg') no-repeat;
						filter: brightness(0) invert(1);
						display: block;
						width: 18px;
						height: 18px;
						float: left;
						margin: 0 6px 0 0;
					}
				}
			}

			&.has-sub-menu,
			&.has-drop-down {
				.link__anchor {
					position: relative;
					padding-right: 0;
				}
			}

			&:not(&.has-sub-menu):not(&.has-drop-down) {
				&:after {
					content: '';
					position: absolute;
					height: 2px;
					background-color: #ba9449;
					border-radius: 2px;
					left: 1em;
					right: 1em;
					bottom: 0;
					opacity: 0;
				}

				&.hover {
					&:after {
						opacity: 1;
					}
				}

				&:first-of-type {
					&:after {
						left: 0;
					}
				}
			}

			&.hover {
				.link__anchor {
					color: transparent;
					&::before {
						color: ${vars.colorWhite};
					}
				}

				.drop-down {
					opacity: 1;
					pointer-events: all;

					&__dynamic-content {
						transform: translate(-30%, 0px);
					}
				}
			}
		}

		.drop-down {
			position: absolute;
			top: 100%;
			left: 0;
			pointer-events: none;
			display: flex;
			flex-direction: column;
			padding: 0.6em;
			min-width: 217px;
			min-height: 1em;
			border-radius: 10px;
			box-shadow: -2px 3px 2px #00000029;
			background-color: ${vars.colorWhite};
			z-index: 2;
			opacity: 0;
			transition: opacity 0.3s ease, transform 0.2s ease;

			&__item {
				color: #535353;
				font-size: 13px;
				padding: 0.3em 0.5em;
				white-space: nowrap;
				text-decoration: none;
				border-radius: 5px;
				width: 100%;

				&:hover {
					background-color: #cbb89d;
					color: ${vars.colorWhite};
					font-weight: 500;
				}
			}

			&__list {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0.4em 0.5em;

				&.hover {
					cursor: pointer;
					background-color: #cbb89d;
					color: ${vars.colorWhite};
					border-radius: 5px;

					.drop-down__item {
						color: ${vars.colorWhite};
						font-weight: 600;
					}
				}
				&.hover .subsection {
					opacity: 1;
					visibility: visible;
				}

				.drop-down__item {
					padding: 0;
					height: 100%;
				}
			}

			&__dynamic-content {
				padding: 1.6em 1.6em 1.4em;
				transform: translate(-30%, -20px);
				left: 0;
			}
		}

		.subsection,
		.sub-subsection {
			position: absolute;
			top: 0;
			left: calc(100% + 5px);
			display: flex;
			flex-flow: column nowrap;
			background-color: ${vars.colorWhite};
			z-index: 1;
			padding: 0.5em;
			min-width: 217px;
			min-height: 1em;
			border-radius: 10px;
			box-shadow: -2px 3px 2px #00000029;

			visibility: hidden;
			opacity: 0;
			transition: opacity 0.3s ease;

			&__element {
				color: #535353;
				font-size: 13px;
				padding: 0.3em 0.5em;
				white-space: nowrap;
				text-decoration: none;
				border-radius: 5px;
				transition: background-color 0.2s ease-in-out;

				a {
					text-decoration: none;
					color: currentColor;
				}

				&.hover {
					background-color: #cfb799;
					color: ${vars.colorWhite};

					.subsection__item {
						color: ${vars.colorWhite};
						font-weight: 600;
					}
				}

				&.golden {
					color: #b9944a;

					a {
						color: #b9944a;
					}
				}

				.pin-icon {
					color: ${vars.colorTextPrimary};
					margin: 0 0.5em 0 0;
				}
			}

			&__element-content {
				display: flex;
				flex-flow: column wrap;
				padding: 0.11em 0;
				gap: 0.1em;
			}
		}

		.sub-subsection {
			display: none;
		}

		.subsection {
            max-height: 80vh;

            .subsection-container {
                overflow-y: auto;
            }

			&__element {
				&.hover {
					cursor: pointer;
					background-color: #cfb799;
					color: #ffffff;

					.sub-subsection {
						opacity: 1;
						visibility: visible;
						display: flex;
					}
				}
			}
		}

		.sub-subsection {
			&__element {
				&:hover {
					background-color: #cfb799;
					color: ${vars.colorWhite};
					font-weight: 600;

					.pin-icon {
						color: ${vars.colorWhite};
					}
				}
			}
		}
	}
`;
